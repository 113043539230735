import { ThumbnailImageProps } from "react-grid-gallery";
import { VideoJSComponent } from "./VideoJSComponent";
import { Video } from "../pages/Videos";
import "../css/style.css";

interface Props {
    data: ThumbnailImageProps;
    backCallback: () => void;
    isVideo?: boolean;
}

const SelectedComponent: React.FC<Props> = ({ data, backCallback, isVideo = false }) => {
    const { src, alt, style, title } = data.imageProps;

    const videoData = data.item as Video;

    return <div className="w-full flex justify-center mt-5">
        <div className={"w-auto max-w-[100rem] pt-3 flex flex-grow items-center justify-center lg:items-start flex-col lg:flex-row gap-5 "}>
            <div className="h-fit w-fit">
                {!isVideo ?
                    <img className="max-h-[35rem] max-w-[45rem] w-full" src={src} alt={alt}></img>
                    :
                    <div className="max-h-[100%] overflow-hidden">
                        <div className="h-[100%] w-full">
                            <VideoJSComponent src={videoData.videoUrl} />
                        </div>
                    </div>
                }
            </div>
            <div className="text-white w-fit lg:max-w-[40%] text-center lg:text-start flex flex-col">
                <p className="font-bold text-xl text-[#39FF14]">{title}</p>
                <p>{data.item.alt}</p>
            </div>
        </div>
    </div>;
}

export default SelectedComponent;