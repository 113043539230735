import { useScript } from "usehooks-ts";
import ThreeDModel from "../threed";
import Background from "../background";
import { Link } from "react-router-dom";
import { Image } from "react-grid-gallery";

import "../css/style.css";

const Home = () => {
  return <div className="w-screen h-screen overflow-x-hidden touch-none">
    <div id="mainContainer" className="h-full w-full flex flex-col fixed touch-none">
      <div className="w-[90%] md:w-[50rem] h-[30%] md:h-[30rem] touch-none">
        <ThreeDModel lightIntensity={0.6} camZ={0.84} fixed={false} filePath="assets/JOVEN_LUCIANO_Junto2.glb"/>
      </div>
      <div className="section-buttons">
        <div className="menubutton"><Link to="/videos">Videos</Link></div>
        <div className="menubutton"><Link to="/photos">Photos</Link></div>
        <div className="menubutton"><Link to="/paintings">Paintings</Link></div>
        <div className="menubutton"><Link to="/illustrations">Illustrations</Link></div>
        <div className="menubutton"><Link to="/sketchbooks">Sketchbooks</Link></div>
        <div className="menubutton"><Link to="https://jovenluciano.bigcartel.com/">Shop</Link></div>
        <div className="menubutton"><Link to="/info">Info</Link></div>
      </div>
    </div>
  </div>

};

export default Home;