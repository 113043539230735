import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

interface ThreeDModelProps {
  fixed: boolean;
  filePath: string;
  camZ?: number;
  fov?: number;
  lightPos?: THREE.Vector3;
  lightIntensity?: number;
  lightDistance?: number;
}

const ThreeDModel: React.FC<ThreeDModelProps> = ({
  fixed,
  lightIntensity = 1,
  lightDistance = 100,
  lightPos = new THREE.Vector3(0, 1, 2),
  fov = 75,
  filePath,
  camZ = 1.5
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const rendererRef = useRef<THREE.WebGLRenderer | null>(null);
  const sceneRef = useRef<THREE.Scene | null>(null);
  const cameraRef = useRef<THREE.PerspectiveCamera | null>(null);
  const modelRef = useRef<THREE.Group | null>(null);
  const clockRef = useRef<THREE.Clock | null>(null);

  useEffect(() => {
    if (!containerRef.current || rendererRef.current) return; // Prevent re-initializing the scene

    // Initialize scene, camera, and renderer
    sceneRef.current = new THREE.Scene();
    cameraRef.current = new THREE.PerspectiveCamera(
      fov,
      containerRef.current.clientWidth / containerRef.current.clientHeight,
      0.1,
      1000
    );
    rendererRef.current = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    rendererRef.current.setSize(containerRef.current.clientWidth, containerRef.current.clientHeight);
    containerRef.current.appendChild(rendererRef.current.domElement);
    rendererRef.current.setClearColor(0x000000, 0); // Transparent background

    clockRef.current = new THREE.Clock();

    // Setup the light
    const logo_light = new THREE.DirectionalLight(0xffffff, lightIntensity);
    logo_light.position.set(lightPos.x, lightPos.y, lightPos.z);
    sceneRef.current.add(logo_light);

    // Inflation Shader Material
    const inflationMaterial = new THREE.ShaderMaterial({
      vertexShader: `
        uniform float time;
        uniform float minInflation;
        uniform float maxInflation;
        varying vec3 vNormal;
        varying vec3 vPosition;
        varying float vInflation;

        void main() {
          vNormal = normalize(normalMatrix * normal);
          vInflation = mix(minInflation, maxInflation, sin(time) * 0.5 + 0.5);
          vec3 inflatedPosition = position + normal * vInflation;
          vPosition = position;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(inflatedPosition, 1.0);
        }
      `,
      fragmentShader: `
        precision mediump float;
        uniform vec3 color1;
        uniform vec3 color2;
        uniform vec3 logo_lightPosition;
        uniform float logo_lightIntensity;
        uniform float metalness;
        uniform float roughness;
        varying vec3 vNormal;
        varying vec3 vPosition;
        varying float vInflation;

        void main() {
          vec3 norm = normalize(vNormal);
          vec3 logo_lightDir = normalize(logo_lightPosition - vPosition);
          vec3 viewDir = normalize(-vPosition);
          vec3 reflectDir = reflect(-logo_lightDir, norm);
          float spec = pow(max(dot(viewDir, reflectDir), 0.0), (1.0 - roughness) * 32.0);
          vec3 specular = vec3(1.0) * spec * metalness;
          float diff = max(dot(norm, logo_lightDir), 0.0) * logo_lightIntensity;

          float colorInflationEffect = clamp((vInflation * 20.0), 0.0, 1.0);
          vec3 mixedColor = mix(color1, color2, colorInflationEffect);

          vec3 diffuse = mixedColor * diff;
          vec3 ambient = mixedColor * 0.1;
          gl_FragColor = vec4(diffuse + ambient + specular, 1.0);
        }
      `,
      uniforms: {
        time: { value: 0 },
        color1: { value: new THREE.Color(0.52, 0.0, 1.0) },
        color2: { value: new THREE.Color('#39FF14') },
        logo_lightPosition: { value: logo_light.position },
        logo_lightIntensity: { value: lightIntensity },
        minInflation: { value: 0 },
        maxInflation: { value: 0.06 },
        normalMatrix: { value: new THREE.Matrix3() }
      }
    });

    // Load the model
    const loader = new GLTFLoader();
    loader.load(
      filePath,
      (gltf) => {
        modelRef.current = gltf.scene;
        modelRef.current.scale.set(3, 3, 3); // Set scale
        modelRef.current.traverse((child) => {
          if ((child as THREE.Mesh).isMesh) {
            (child as THREE.Mesh).material = inflationMaterial;
          }
        });

        const box = new THREE.Box3().setFromObject(modelRef.current);
        const center = box.getCenter(new THREE.Vector3());
        modelRef.current.position.sub(center);

        const size = new THREE.Vector3();
        box.getSize(size);
        const maxDim = Math.max(size.x, size.y, size.z);
        const fovRad = cameraRef.current!.fov * (Math.PI / 180);
        let cameraZ = Math.abs(maxDim / 2 / Math.tan(fovRad / 2));
        cameraZ *= camZ;

        cameraRef.current!.position.set(0, 0, cameraZ);
        cameraRef.current!.near = maxDim / 100;
        cameraRef.current!.far = maxDim * 100;
        cameraRef.current!.updateProjectionMatrix();

        sceneRef.current!.add(modelRef.current);
      },
      undefined,
      (error) => {
        console.error('An error happened:', error);
      }
    );

    const animate = () => {
      requestAnimationFrame(animate);
      inflationMaterial.uniforms.time.value = clockRef.current!.getElapsedTime();
      inflationMaterial.uniforms.logo_lightIntensity.value = lightIntensity;
      rendererRef.current!.render(sceneRef.current!, cameraRef.current!);
    };

    animate();

    const handleResize = () => {
      if (cameraRef.current && rendererRef.current && containerRef.current) {
        cameraRef.current.aspect = containerRef.current.clientWidth / containerRef.current.clientHeight;
        cameraRef.current.updateProjectionMatrix();
        rendererRef.current.setSize(containerRef.current.clientWidth, containerRef.current.clientHeight);

        if (modelRef.current && !fixed) {
          const scaleFactor = window.innerWidth < 1000 ? window.innerWidth / 1000 : 3;
          modelRef.current.scale.set(scaleFactor, scaleFactor, scaleFactor);
        }
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [filePath, fixed, camZ, fov, lightIntensity, lightPos]);

  return <div ref={containerRef} style={{ width: '100%', height: '100%', position: 'relative' }} />;
};

export default ThreeDModel;
